import { useState, useEffect, createContext, useContext } from "react"
import * as authHelper from "./AuthHelpers"

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  user: undefined,
  setCurrentUser: () => {},
  logout: () => {}
}

const AuthContext = createContext(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState()
  const saveAuth = (auth) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()

  const [showSplashScreen, setShowSplashScreen] = useState(false)
  useEffect(() => {
    if (auth && auth.token) {
      setCurrentUser(auth.data)
      setShowSplashScreen(false)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])
  // <LayoutSplashScreen />
  return showSplashScreen ? <h1>Loading</h1> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
