import React, { useEffect } from "react";

const FacebookEmbed = () => {
  useEffect(() => {
    // Load the external script after the component mounts
    const script = document.createElement("script");
    script.src = "https://www.embedista.com/j/fbwidget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <iframe
        frameborder="0"
        width="440"
        height="470"
        src="https://www.facebook.com/v9.0/plugins/page.php?adapt_container_width=true&amp;app_id=113869198637480&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df310a21b6f5a654%26domain%3Ddevelopers.facebook.com%26origin%3Dhttps%253A%252F%252Fdevelopers.facebook.com%252Ff3cf179cd85d47c%26relation%3Dparent.parent&amp;container_width=560&amp;height=560&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2F/starlinknomad&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false&amp;tabs=timeline&amp;width=500"
      ></iframe>
      <script
        type="text/javascript"
        src="https://www.embedista.com/j/fbwidget.js"
      ></script>
      <div id="fbroot"></div>
      <div>
        {/* <a href="https://www.facebook.com/starlinknomad">
          Facebook хуудас руу очих
        </a> */}
      </div>
      <div
        class="fblike"
        data-width=""
        data-layout=""
        data-action=""
        data-size=""
        data-share="true"
      ></div>
    </div>
  );
};

export default FacebookEmbed;
