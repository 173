import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import gen3 from "../../assets/others/starlink_gen3.png";
import stants from "../../assets/others/yavdag_stants.png";
import usage from "../../assets/gallery/gen3-1.png";
import Header from "../../Header";
import ToolbarSecond from "../../ToolbarSecond";

import FooterL from "../../Footer";

const RentMain = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(1);
  console.log("selectedItem: ", selectedItem);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const sItems = [
    {
      id: 1,
      name: "Starlink Nomad - Явдаг интернэт",
      img: gen3,
      price: 6500000,
    },
    {
      id: 2,
      name: "Аяллын Явдаг Станц",
      img: stants,
      price: 5000000,
      accessories: [
        "220V залгуур (2м)",
        "Зурагдалтаас хамгаалах хуулга (8ш)",
        "Хэрэгслүүдийн уут",
      ],
    },
  ];

  return (
    <>
      <div>
        <div className="buy-main-container">
          <Header />
          <ToolbarSecond></ToolbarSecond>

          <div className="rent-container">
            <h3
              className="rent-name"
            >
              Түрээсийн төхөөрөмжүүд
            </h3>
            <div className="rent-products">
              {sItems.map((item, index) => (
                <div
                  className={`rent-item ${hoveredIndex === 1 ? "selected" : ""
                    }`}
                  onClick={() => navigate(`/rent/${item.id}`)}
                >
                  <div className="rent-image"> 
                    <img
                    className="starlink-icon"
                    src={item.img}
                    alt="Starlink Gen3 Small"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  />
                  </div>
                  <span
                    className="rent-product-title"
                    style={{ marginTop: 20 }}
                  >
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
          style={{
            backgroundColor: "#f7f9fa",
          }}
          className="buy-button-container "
        >
          <FooterL></FooterL>

        </div>
    </>
  );
};

export default RentMain;
