import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Select, notification } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import ToolbarSecond from "../../ToolbarSecond";
import Header from "../../Header";
import gen3 from "../../assets/others/starlink_gen3.png";
import stants from "../../assets/others/yavdag_stants.png";
import b_long from "../../assets/black_logo_long.png";

const products = [
  {
    id: 1,
    name: "Starlink Nomad - Явдаг интернэт",
    img: gen3,
    price: 100000,
    rentPrice: [100000, 90000, 80000, 70000],
    headerText:
      "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",
    descMain:
      "Starlink Nomad Gen3 нь манай хамгийн сүүлд гаргасан хөдөлгөөнт загвар юм.",
    descriptionText: [
      "Өмнөх Gen2 загвараас 30%-р интернэтийн хурд, сүлжээний тогтвортой байдлаар илүү.",
      "Gen2 -c 5 дахин хурдан сүлжээнд холбогдоно.",
      "Gen2 нь WiFi 5 байдаг бол Gen3-ын WiFi6 цацагч илүү өргөн талбайд сүлжээ цацна",
      "2 ширхэг LAN гаргалттай.",
      "Хэрэглээний төлбөр 210.000 (нэг цэгт), 250.000 (олон цэг)",
      "Дата хязгааргүй, хэрэглэхгүй сараа зогсоож болно.",
      "Хурд: 200-400mbps.",
      "6 сарын баталгаа.",
      "Идэвхжүүлэлт холболтын зөвлөгөө.",
      "Баталгаат засвар үйлчилгээ.",
    ],
    accessories: [
      "12V залгуур (3м)",
      "Баттерэйнд холбогч",
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Хэрэгслүүдийн уут",
    ],
  },
  {
    id: 2,
    name: "Аяллын Явдаг Станц",
    img: stants,
    price: 5000000,
    accessories: [
      "220V залгуур (2м)",
      "Зурагдалтаас хамгаалах хуулга (8ш)",
      "Хэрэгслүүдийн уут",
    ],
    rentPrice: [80000, 70000, 60000, 50000],
    descMain:
      "Starlink Nomad Gen3 нь манай хамгийн сүүлд гаргасан хөдөлгөөнт загвар юм.",
    descriptionText: [
      "Өмнөх Gen2 загвараас 30%-р интернэтийн хурд, сүлжээний тогтвортой байдлаар илүү.",
      "Gen2 -c 5 дахин хурдан сүлжээнд холбогдоно.",
      "Gen2 нь WiFi 5 байдаг бол Gen3-ын WiFi6 цацагч илүү өргөн талбайд сүлжээ цацна",
      "2 ширхэг LAN гаргалттай.",
      "Хэрэглээний төлбөр 210.000 (нэг цэгт), 250.000 (олон цэг)",
      "Дата хязгааргүй, хэрэглэхгүй сараа зогсоож болно.",
      "Хурд: 200-400mbps.",
      "6 сарын баталгаа.",
      "Идэвхжүүлэлт холболтын зөвлөгөө.",
      "Баталгаат засвар үйлчилгээ.",
    ],
  },
];
const ShoppingCardRent = ({ min = 1, max = 8, step = 1 }) => {
  const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
  const [form] = Form.useForm();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [duration, setDuration] = useState(0);
  const [counts, setCounts] = useState(
    products.reduce((acc, item) => ({ ...acc, [item.id]: min }), {})
  );
  const navigate = useNavigate();
  const [componentSize, setComponentSize] = useState("default");
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
      const [isVisible, setIsVisible] = useState(false);

  const callInstruction = () => {
    if (isVisible === true) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  const calculateTotalPrice = () => {
    const newTotalPrice = cartItems.reduce(
      (sum, item) => sum + item.price * duration * (counts[item.id] || min),
      0
    );
    setTotalPrice(newTotalPrice);
  };
  useEffect(() => {
    calculateTotalPrice();
  }, [cartItems, counts]);

  useEffect(() => {
    const storedItems = localStorage.getItem("cartRentItems");
    const duration = localStorage.getItem("duration");
    setDuration(duration);
    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      setCartItems(parsedItems);

      const newTotalPrice = parsedItems.reduce(
        (sum, selectedItem) => sum + selectedItem.price * duration,
        0
      );
      setTotalPrice(newTotalPrice);
    }
  }, []);

  const handleDecrease = (id) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [id]: Math.max(prevCounts[id] - step, min),
    }));
  };

  const handleIncrease = (id) => {
    setCounts((prevCounts) => ({
      ...prevCounts,
      [id]: Math.min(prevCounts[id] + step, max),
    }));
  };
  const buyConfirm = async (values) => {
    const formData = form.getFieldsValue();
    if (!formData.email || !formData.phone1) {
      notification.error({
        message: "Мэдээлэл дутуу байна.",
        description: "Та шаардлагатай мэдээллүүдийг оруулна уу",
        duration: 3,
      });
      return;
    }

    try {
      console.log("ba: ", cartItems[0].duration);

      const response = await fetch(`${backendApiUrl}/email/send/2`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "oyukaauu@gmail.com",
          subject: "Захиалгын мэдээлэл",
          text: "Таны захиалгын дэлгэрэнгүй",
          html: `
            <p>Таны түрээслэхийг хүссэн төхөөрөмжүүдийн жагсаалт</p>
            <p>Таны хүсэлт илгээгдсэнээр манай ажилтан холбогдож төхөөрөмж боломжтой эсэхийг мэдэгдэх болно. Танд асуух зүйл байвал 75990033, 99996384 дугаар болон <a href="https://www.facebook.com/starlinknomad" target="_blank">
            Facebook мессэнжэрээр
          </a>
           холбогдоно уу.</p>
           <p>Төхөөрөмжүүдийн үнээс хамаарч барьцаа байршуулахыг анхаарна уу. Дэлгэрэнгүй мэдээллийг ажилтнаас авна уу.</p>
            <p><strong>Items:</strong></p>
            <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="border: 1px solid #ddd; padding: 8px;">Төхөөрөмж</th>
                <th style="border: 1px solid #ddd; padding: 8px;">Хугацаа</th>
                <th style="border: 1px solid #ddd; padding: 8px;">Үнэ</th>
                <th style="border: 1px solid #ddd; padding: 8px;">Тоо ширхэг</th>
                <th style="border: 1px solid #ddd; padding: 8px;">Нийт дүн</th>
              </tr>
            </thead>
            <tbody>
              ${cartItems
                .map(
                  (item) => `
                <tr>
                  <td style="border: 1px solid #ddd; padding: 8px;">${
                    item.name
                  }</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${
                    item.duration
                  } хоног</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${item.price.toLocaleString()} MNT</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${
                    counts[item.id] || min
                  }</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${(
                    item.price * (counts[item.id] || min)
                  ).toLocaleString()} MNT</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
            <p><strong>Нийт үнэ (НӨАТ орсон):</strong> ${totalPrice.toLocaleString()} MNT</p>
            <p><strong>Имэйл:</strong> ${formData.email}</p>
            <p><strong>Утасны дугаар1:</strong> ${formData.phone1}</p>
            <p><strong>Утасны дугаар2:</strong> ${formData.phone2}</p>
            <p><strong>Таны хэрэглэх цахилгаан:</strong> ${
              formData.electricity
            }</p>
            <p>Автомат имэйл</p>
          `,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Email sent successfully:", data);
      notification.success({
        message: "Order Successful",
        description:
          "Your order has been placed successfully. Please check your email!",
        duration: 3,
        onClose: () => {
          localStorage.removeItem("cartItems");
          navigate("/");
        },
      });
    } catch (error) {
      console.error("Error sending email:", error);
      notification.error({
        message: "Order Failed",
        description:
          "There was an error placing your order. Please try again later.",
      });
    }
  };

  const removeItem = (id) => {
    const updatedItems = cartItems.filter((item) => item.id !== id);

    setCartItems(updatedItems);
    localStorage.setItem("cartRentItems", JSON.stringify(updatedItems));

    const newTotalPrice = updatedItems.reduce(
      (sum, selectedItem) => sum + selectedItem.price,
      0
    );
    setTotalPrice(newTotalPrice);
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
        }}
        className="flex flex-col min-h-screen"
      >
        <Header></Header>
        <ToolbarSecond></ToolbarSecond>

        <div
          style={{
            maxWidth: 1400,
            margin: "0px auto",
          }}
        >
          <div
            className="href-text "
            style={{ marginLeft: -50, fontSize: 24, marginTop: 80 }}
          >
            <div>
              Та түрээслэх огноо болон түрээслэх төхөөрөмжүүдийн тоо ширхэгийг
              зөв эсэхийг нягтална уу.
            </div>
            <div>
              Таны хүсэлт илгээгдсэнээр манай ажилтан холбогдож төхөөрөмж
              боломжтой эсэхийг мэдэгдэх болно.
            </div>
            <div>
              Төхөөрөмжүүдийн үнээс хамаарч барьцаа байршуулахыг анхаарна уу.
              Дэлгэрэнгүй мэдээллийг ажилтнаас авна уу.
            </div>
          </div>
        </div>
        <div
          style={{
            width: 1400,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 40,
          }}
        >
          <span style={{ fontSize: 32 }}>
            {" "}
            Таны түрээслэхээр сонгосон төхөөрөмжүүдийн жагсаалт
          </span>
          <div style={{ marginTop: 40 }}>
            <div className="bs-check-grid">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    fontSize: 18,
                    marginLeft: 40,
                  }}
                >
                  Төхөөрөмж
                </div>
                <div
                  style={{ width: "10%", fontSize: 18, textAlign: "center" }}
                >
                  Хоног
                </div>
                <div
                  style={{ width: "10%", fontSize: 18, textAlign: "center" }}
                >
                  Үнэ
                </div>
                <div
                  style={{ width: "10%", fontSize: 18, textAlign: "center" }}
                >
                  Тоо
                </div>
                <div
                  style={{ width: "15%", fontSize: 18, textAlign: "center" }}
                >
                  Нийт дүн
                </div>
                <div style={{ width: "5%", fontSize: 18 }}></div>
              </div>
              <hr></hr>
              {cartItems.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "60%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {/* <Checkbox
                      className="bs-checkbox"
                      checked={selectedAdditionalItems.includes(item.id)}
                      onChange={() => handleAdditionalSelect(item.id)}
                    ></Checkbox> */}
                    <div style={{ width: 140 }}>
                      <div className="bs-check-cont">
                        <img
                          alt={item.name}
                          className="starlink-icon"
                          src={item.img}
                        />
                      </div>
                    </div>
                    <p className="bs-check-cont-2-name">{item.name}</p>
                  </div>
                  <p
                    className="bs-check-cont-2-name"
                    style={{ width: "5%", textAlign: "center" }}
                  >
                    {duration}
                  </p>
                  <p
                    className="bs-check-cont-2-name"
                    style={{ width: "10%", textAlign: "center" }}
                  >
                    {item.price.toLocaleString()}
                  </p>

                  <div
                    style={{ width: "10%", textAlign: "center" }}
                    className="counter-container"
                    data-test-locator="counter"
                  >
                    <Button
                      type="button"
                      data-test-locator="btnDecrease"
                      aria-label="minus"
                      onClick={() => handleDecrease(item.id)}
                      disabled={counts[item.id] <= min}
                    >
                      <MinusOutlined />
                    </Button>
                    <Input
                      step={step}
                      min={min}
                      max={max}
                      value={counts[item.id]}
                    ></Input>
                    <Button
                      type="button"
                      data-test-locator="btnIncrease"
                      aria-label="plus"
                      onClick={() => handleIncrease(item.id)}
                      disabled={counts[item.id] >= max}
                    >
                      <PlusOutlined />
                    </Button>
                  </div>
                  <p className="bs-check-cont-2-name" style={{ width: "15%" }}>
                    {(item.price * (counts[item.id] || min)).toLocaleString()}{" "}
                    MNT
                  </p>
                  <div
                    style={{
                      height: 30,
                      willChange: 30,
                      marginTop: 10,
                      width: "5%",
                    }}
                    onClick={() => removeItem(item.id)}
                  >
                    <img
                      alt={item.name}
                      className="starlink-icon"
                      src="https://static.thenounproject.com/png/972445-200.png"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: 1400,
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 40,
                marginBottom: 100,
              }}
            >
              <div style={{ width: 1000 }}></div>
              <Form
                form={form}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                style={{
                  fontSize: 18,
                }}
              >
                <Form.Item
                  name="phone1"
                  label="Утасны дугаар 1"
                  style={{ fontSize: 18, width: 600 }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="phone2" label="Утасны дугаар 2">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Имэйл"
                  required
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="electricity" label="Таны хэрэглэх цахилгаан">
                  <Select>
                    <Select.Option value="12V">12V</Select.Option>
                    <Select.Option value="220V">220V</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <div
        style={{
          backgroundColor: "#f7f9fa",
        }}
        className="buy-button-container "
      >
        <div  className="fl-container" style={{marginBottom:40}} >
        <div className="fl-sub">
          <div className="fl-sub-1 ">
            <div className="fl-img">
              <img style={{ height: "100%", width: "100%" }} src={b_long}></img>
            </div>

            <div
              className="fl-txt "
              onClick={() => navigate(`/home?id=${"starlink3"}`)}
            >
              Түгээмэл асуултууд
            </div>
            <div className="fl-txt " onClick={() => navigate("/contact")}>
              Холбоо барих
            </div>
            <div className="fl-txt" onClick={callInstruction}>
              Заавар татах
            </div>
            {isVisible && (
              <div
                className="fl-txt"
                onClick={() =>
                  window.open("./assets/pdf/MobileBurtgel-StarlinkNomad.pdf")
                }
              >
                Хөдөлгөөнт идэвхжүүлэх
              </div>
            )}
            {isVisible && (
              <div
                className="fl-txt"
                onClick={() =>
                  window.open("./assets/pdf/SuurinBurtgel-StarlinkNomad.pdf")
                }
              >
                Суурин идэвхжүүлэх
              </div>
            )}
          </div>

          <div className="bs-price-cont ">
            <span className="bs-pric-txt">
              MNT {totalPrice && totalPrice.toLocaleString()}
            </span>
            <button className="bs-button" style={{width:290}} onClick={buyConfirm}>
            ЗАХИАЛГА БАТАЛГААЖУУЛАХ
            </button>
          </div>

        </div>
        <div className="fl-hr">
          <hr className="fl-hr-s" />
        </div>
        <div className="fl-cont-2">
          <div className="fl-add">
            Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503
            тоот(Циркийн урд)
          </div>
          <div className="fl-phone" style={{marginRight:5}}>+976 75990033</div>
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default ShoppingCardRent;
