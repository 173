import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {TRANSLATIONS_EN} from "./translation/translations";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            EN: {
                translation: TRANSLATIONS_EN
            },
            MN: {
                translation:{}
            }
        },
        fallbackLng: 'MN',
        debug: true,

        interpolation: {
            escapeValue: false, 
        }
    });

export default i18n;