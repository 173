import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthInit } from "./auth";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const App = () => {
  return (
    <Suspense fallback={<>aaa</>}>
        <I18nextProvider i18n={i18n}>
        <AuthInit>
          <Outlet />
        </AuthInit>
        </I18nextProvider>
    </Suspense>
  );
};

export default App;
