const AUTH_LOCAL_STORAGE_KEY = "portal_auth"
const getAuth = () => {
  if (!localStorage) {
    return
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth = JSON.parse(lsValue)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error)
  }
}

const setAuth = (auth) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error)
  }
}

export function setupAxios(axios) {
  axios.defaults.headers.Accept = "application/json"
  axios.interceptors.request.use(
    (config) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        removeAuth()
        document.location.reload()
      }
      throw new Error(error.response?.data?.message)
    }
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
