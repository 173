import React from "react";
import { useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  ProjectOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

import { Tooltip } from "antd";

const ToolbarSecond = () => {
  const navigate = useNavigate();

  const handleSupportClick = () => {
    navigate(`/home?id=${"starlink3"}`);
  };
  return (
    <div className="fixed-buttons-container centered">
      <Tooltip title="НҮҮР" placement="right">
        <button
          onClick={() => navigate(`/home`)}
          className={`toolbar-button-black`}
        >
          <HomeOutlined />
        </button>
      </Tooltip>
      <Tooltip title="ТҮРЭЭСЛЭХ" placement="right">
        <button
          onClick={() => navigate(`/rent-list`)}
          className={`toolbar-button-black`}
        >
          <ProjectOutlined />
        </button>
      </Tooltip>
      <Tooltip title="АСУУЛТ, ХАРИУЛТ" placement="right">
        <button onClick={handleSupportClick} className={`toolbar-button-black`}>
          <QuestionOutlined />
        </button>
      </Tooltip>
    </div>
  );
};

export default ToolbarSecond;
