import React, { useEffect, useState } from "react";
import { DownOutlined, MenuOutlined, QuestionOutlined, UpOutlined } from "@ant-design/icons";

import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const ToolbarLanding = () => {
  const idList = ["landing", "starlink", "starlink2", "intro", "starlink3"];
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  
  const [isBlinking, setIsBlinking] = useState(false);
  useEffect(() => {
    setIsBlinking(true);
    const timer = setTimeout(() => {
      setIsBlinking(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const toggleVisibility = () => {
    const scrollContainer = document.querySelector(".background-container");
    if (scrollContainer.scrollTop > 850) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    setCurrentSectionIndex(getCurrentSectionIndex());
  };

  const handleSupportClick = () => {
    navigate(`/home?id=${"starlink3"}`);
  };

  const getCurrentSectionIndex = () => {
    for (let i = 0; i < idList.length; i++) {
      const section = document.getElementById(idList[i]);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight) {
          return i;
        }
      }
    }
    return -1;
  };

  const scrollToNextSection = () => {
    if (currentSectionIndex >= 0 && currentSectionIndex < idList.length - 1) {
      const nextSectionId = idList[currentSectionIndex + 1];
      const nextSection = document.getElementById(nextSectionId);
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: "smooth" });
      }

      if (currentSectionIndex === 4) {
      }
    }
  };

  useEffect(() => {
    const scrollContainer = document.querySelector(".background-container");
    scrollContainer.addEventListener("scroll", toggleVisibility);
    return () => {
      scrollContainer.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed-buttons-container centered">
      <Tooltip title="  ӨМНӨХ" placement="right">
        <button onClick={handleSupportClick} className={`toolbar-button`} style={{opacity:0.5}}>
          <UpOutlined />
        </button>
      </Tooltip>
      <Tooltip title="АСУУЛТ, ХАРИУЛТ" placement="right">
        <button onClick={handleSupportClick} className={`toolbar-button`}>
          <MenuOutlined />
        </button>
      </Tooltip>
      {currentSectionIndex < idList.length - 1 && currentSectionIndex !== 4 && (
        <Tooltip title="ДАРААХ" placement="right">
          <button onClick={scrollToNextSection} className={`toolbar-button blinking`}>
            <DownOutlined />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default ToolbarLanding;
