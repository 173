import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "./assets/black_logo_long.png";

const Header = () => {
  const navigate = useNavigate();
  const [cartCount2, setCartCount] = useState(0);
  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    setCartCount(cartItems ? JSON.parse(cartItems).length : 0);
  }, [cartCount2]);

  const callShoppingCard = () => {
    navigate(`/sp`);
  };

  return (
    <div className="header" style={{ background: "rgb(240, 241, 242)" }}>
      <div
        style={{
          width: 1400,
          background: "rgb(240, 241, 242)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        <div
          onClick={() => navigate(`/home`)}
          style={{ height: 25, alignItems: "flex-start" }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
            onClick={callShoppingCard}
          >
            <img
              src="https://png.pngtree.com/element_our/20190531/ourmid/pngtree-shopping-cart-convenient-icon-image_1287807.jpg"
              alt="Shopping Bag"
              style={{ height: 24, width: 24 }} // Adjust the size as needed
            />
            <span
              style={{
                position: "absolute",
                top: -5,

                right: -10,
                background: "red",
                color: "white",
                borderRadius: "50%",
                padding: "0 5px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {cartCount2}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
