import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import { Logout } from "./auth";
import Home from "./Home";
import BuyFlight from "./components/buy/BuyFlight";
import BuyMain from "./components/buy/BuyMain";
import AllProduct from "./components/buy/AllProduct";
import Contact from "./components/starlink/Contact";
import RentMain from "./components/rent/RentMain";
import RentProduct from "./components/rent/RentProduct";
import ShoppingCard from "./components/buy/ShoppingCard";
import ShoppingCardRent from "./components/buy/ShoppingCardRent";
// import RentProductDetail from "./components/rent/RentProductDetail";

const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  const storedUser = localStorage.getItem("user");
  const userData = JSON.parse(storedUser);
  const phone = (userData && userData.phone) || "";

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Home />} />
          <Route path="logout" element={<Logout />} />
          <Route path="home" element={<Home />} />
          <Route path="buy-list" element={<BuyMain />} />
          <Route path="buy/:id" element={<BuyFlight />} />
          <Route path="all/:id" element={<AllProduct />} />
          <Route path="rent-list" element={<RentMain />} />
          <Route path="rent/:id" element={<RentProduct />} />
          <Route path="contact" element={<Contact />} />
          <Route path="sp" element={<ShoppingCard />} />
          <Route path="spr" element={<ShoppingCardRent />} />
          {/* <Route path="rent/:id/detail" element={<RentProductDetail />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
