import React from "react";
import p2 from "../../assets/spacex.png";

const Intro2 = () => {
  return (
    <div className="ssl-container-out">
      <h3 className="header-intro"
      // style={{ marginTop: 100, marginBottom: -80, color: "white" }}
      >
        Starlink гэж юу вэ?
      </h3>
      <div className="ssl-container">
        <div className="ssl-img-cont ">
          <img className="pr-img shadow-4 ssl-img" src={p2} alt="Product 2" />
        </div>
        <div className="ssl-second">
          <div className="padding-40">
            <p className="pr-p-2">
              Starlink бол Дэлхийн бүх цэгийг интернэтэд холбох зорилгоор SpaceX компанийн байрлуулсан Дэлхийгээс ойролцоогоор 550км буюу нам орбитын тойрог замд орших олон мянган хиймэл дагуулуудын систем юм. Төслийн ажил тэрбумтан Илон Маскийн санаачилгаар 2015 онд эхэлсэн бөгөөд 2018 оны 2-р сард "TINTIN" туршилтын хоёр хиймэл дагуул хөөргөсний дараа 2019 оны 5-р сард Starlink хиймэл дагуулын 60 ширхэг анхны багцыг хөөргөсөн. Түүнээс хойш “SpaceX” компани Falcon 9 болон Starship сансрын хөлгүүдээрээ нэг удаагийн нислэгтээ 50-100 ширхэг Starlink хиймэл дагуулуудыг тээвэрлэн сүлжээгээ өргөжүүлсээр байна.
            </p>
            <p className="pr-p-2">
              Үүнтэй төстэй санааг Старлинкээс өмнө бусад компани, байгууллагууд гаргаж байв. Гэхдээ “SpaceХ” компанийн санаачилга цар хүрээ болон өөрийн пуужингийн технологитой хослуулснаар онцгой байлаа. 2024 оны 8 сарын байдлаар Дэлхийн тойрог замд нийт 6000 Starlink хиймэл дагуул байршаад байна.
            </p>
            <p className="pr-p-2">
              SpaceX ирээдүйд мега од гэгдэх 42,000 хиймэл дагуултай болохоор зорьж байна. 2020 онд SpaceX компани нь АНУ, Канадад арилжааны интернэт үйлчилгээг үзүүлж эхэлснээс хойш Дэлхий даяар 2024 оны 8 сарын байдлаар 3.5 сая хэрэглэгчтэй болсон гэж мэдэгдээд байна.
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Intro2;
