export const TRANSLATIONS_MN = {
    welcome: "Тавтай морил",
    hello: "Hello, {name}!",
    "WELCOME TO STARTLINK NOMAD": "STARTLINK NOMAD ТАВТАЙ МОРИЛ",
    HOME: "НҮҮР",
    "PRODUCTS":"БҮТЭЭГДЭХҮҮН ҮЙЛЧИЛГЭЭ"
};

export const TRANSLATIONS_EN = {
    "Тавтай морил": "welcome",
    hello: "Hello, {name}!",
    "STARTLINK NOMAD ТАВТАЙ МОРИЛ":"WELCOME TO STARTLINK NOMAD",
    "Хялбар, авсаархан, бүгдийг нэг дор холбох шийдэл бүхий хүртээмжтэй, зөөврийн, хүчирхэг технологи": "Easy, Compact, All-in-One Connectivity Solution. Accessible, Portable, and Powerful",
    "ТӨХӨӨРӨМЖ АВАХ":"BUY DEVICES",
    "ТҮРЭЭСЛЭХ":"RENT DEVICES",
    "НҮҮР": "HOME",
    "БҮТЭЭГДЭХҮҮН": "PRODUCTS"
};
