import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import imageIcon from "../../assets/icons/images.png";
import u1 from "../../assets/usage-1.jpg";
import u2 from "../../assets/usage-4.jpg";
import { DatePicker, Input, Modal, notification, Tabs } from "antd";
import gen3 from "../../assets/others/starlink_gen3.png";
import stants from "../../assets/others/yavdag_stants.png";
import solar_panel from "../../assets/others/solar_panel.png";
import ToolbarSecond from "../../ToolbarSecond";

import outdoor_wifi6 from "../../assets/others/outdoor_wifi6.png";
import Header from "../../Header";
import Gallery from "../SliderGallery";
import fb_black from "../../assets/icons/7_black.png";
import insta_black from "../../assets/icons/8_black.png";

const RentProduct = () => {
  const { id } = useParams();
  const [itemId] = useState(parseInt(id));
  const [tabPosition] = useState("bottom");
  const { RangePicker } = DatePicker;
  const step = 1;
  const min = 1;
  const max = 8;
  const additionals = [
    {
      id: 1,
      subItems: [
        {
          id: 5,
          name: "Интернэт цацах хүрээг 250м хүртэл диаметр тэлж өгнө.",
          img: outdoor_wifi6,
          price: 50000,
          priceTag: "50,000 ~ 30,000",
        },
        {
          id: 2,
          name: "Аяллын Явдаг Станц - Старлинкийг 24-36 цаг ажиллуулна. Машинаас 5 цагт бүрэн цэнэгээ авна. 2300Wh capacity",
          img: stants,
          price: 80000,
          priceTag: "80,000 ~ 50,000",
        },
        {
          id: 3,
          name: "Зөөврийн нарны панель 300Watt ",
          img: solar_panel,
          price: 50000,
          priceTag: "50,000 ~ 50,000",
        },
      ],
    },
    {
      id: 2,
      subItems: [
        {
          id: 5,
          name: "Интернэт цацах хүрээг 250м хүртэл диаметр тэлж өгнө.",
          img: outdoor_wifi6,
          price: 50000,
          priceTag: "50,000 ~ 30,000",
        },
        {
          id: 2,
          name: "Аяллын Явдаг Станц - Старлинкийг 24-36 цаг ажиллуулна. Машинаас 5 цагт бүрэн цэнэгээ авна. 2300Wh capacity",
          img: stants,
          price: 80000,
          priceTag: "80,000 ~ 50,000",
        },
        {
          id: 3,
          name: "Зөөврийн нарны панель 300Watt ",
          img: solar_panel,
          price: 50000,
          priceTag: "50,000 ~ 30,000",
        },
      ],
    },
  ];

  const sItems = [
    {
      id: 1,
      name: "Starlink Nomad - Явдаг интернэт",
      img: gen3,
      price: 100000,
      rentPrice: [100000, 90000, 80000, 70000],
      headerText:
        "Монгол орны байгаль цаг агаар, замын нөхцөл, аврагчид, аялагчид, малчдад зориулан өөрчилж хялбаршуулсан Старлинкийн сансрын интернэтийн хөдөлгөөнт төхөөрөмж Starlink Nomad.",
      descMain:
        "Starlink Nomad Gen3 нь манай хамгийн сүүлд гаргасан хөдөлгөөнт загвар юм.",
      descriptionObject: [
        {
          id: 1,
          value: [
            "Өмнөх Gen2 загвараас 30%-р интернэтийн хурд, сүлжээний тогтвортой байдлаар илүү.",
          ],
        },
        { id: 2, value: ["Gen2 -c 5 дахин хурдан сүлжээнд холбогдоно."] },
        {
          id: 3,
          value: [
            "Gen2 нь WiFi 5 байдаг бол Gen3-ын WiFi6 цацагч илүү өргөн талбайд сүлжээ цацна",
          ],
        },
        { id: 4, value: ["2 ширхэг LAN гаргалттай."] },
        {
          id: 5,
          value: ["Хэрэглээний төлбөр 210.000 (нэг цэгт), 250.000 (олон цэг)"],
        },
        { id: 6, value: ["Дата хязгааргүй, хэрэглэхгүй сараа зогсоож болно."] },
        { id: 7, value: ["Хурд: 200-400mbps."] },
        { id: 8, value: ["6 сарын баталгаа."] },
        { id: 9, value: ["Идэвхжүүлэлт холболтын зөвлөгөө."] },
        { id: 10, value: ["Баталгаат засвар үйлчилгээ."] },
      ],
      accessories: [
        "12V залгуур (3м)",
        "Баттерэйнд холбогч",
        "220V залгуур (2м)",
        "Зурагдалтаас хамгаалах хуулга (8ш)",
        "Хэрэгслүүдийн уут",
      ],
    },
    {
      id: 2,
      name: "Аяллын Явдаг Станц",
      img: stants,
      price: 80000,
      // accessories: [
      //   "220V залгуур (2м)",
      //   "Зурагдалтаас хамгаалах хуулга (8ш)",
      //   "Хэрэгслүүдийн уут",
      // ],
      rentPrice: [80000, 70000, 60000, 50000],
      descMain:
        "Аяллын кампер машины цахилгаан хангамжийн шийдлийг хялбаршуулан нэг хайрагт багтаасан Монгол инженерийн бүтээл. Аяллын замд машинаас 5 цагт бүрэн цэнэгээ авна.",
      descriptionObject: [
        { id: 1, value: ["Цэнэгийн үзүүлэлт: 2300Wh 180Аh 12.8V"] },
        { id: 2, value: ["Баттерэй төрөл: LiFePo4 (Lithium iron phosphate)"] },
        { id: 3, value: ["Цэнэг авах насжилт: 4000 удаа (10+жил)"] },
        { id: 4, value: ["Аюулын зэрэг: Баттерэй төрөлдөө хамгийн аюулгүй"] },
        {
          id: 5,
          value: [
            "Хяналт, мэдээлэл:: Bluetooth Аpp iOS болон Android. 20м хүртэл зайнаас холбогдоно.",
          ],
        },
        {
          id: 6,
          value: ["Гаралтын портууд:"],
          subValue: [
            "  AC 220V 400W",
            "  USB x3",
            "  Type C PD x1",
            "  Cigar x2",
            "  Anderson 50A x4, 175A x1",
          ],
        },
        {
          id: 7,
          value: ["Оролтын портууд:"],
          subValue: [
            "  Машины генератороос цэнэглэх x1 (12V 40А Anderson)",
            "  220С AC to DC 12V цэнэглэх x4 (50A MAX Anderson)",
            "  Нарны хавтангаас цэнэглэх",
            "    Controlled 12V x4 (50A MAX Anderson)",
            "    Uncontrolled to 32V x1 (40A Anderson)",
          ],
        },
        { id: 8, value: ["Хэмжээ: W22.5 x L38 x H47cm"] },
        { id: 9, value: ["Жин: 20,5кг"] },
        { id: 10, value: ["Холболт суурилуулалт үнэ төлбөргүй"] },
        { id: 11, value: ["6 сарын баталгаа."] },
        { id: 12, value: ["Баталгаат засвар үйлчилгээ."] },
      ],
      accessories: [
        "12V залгуур (3м)",
        "Баттерэйнд холбогч",
        "220V залгуур (2м)",
        "Зурагдалтаас хамгаалах хуулга (8ш)",
        "Төмөр суурь",
        "Хэрэгслүүдийн уут",
      ],
    },
  ];
  const navigate = useNavigate();
  const [deviceCount, setDeviceCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [mainPrice, setMainPrice] = useState(0);
  const [addPrice, setAddPrice] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateDuration, setDateDuration] = useState(0);
  const [selectedAdditionalItems, setSelectedAdditionalItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const callInstruction = () => {
    if (isVisible === true) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };
  const handleFbClick = () => {
    window.open("https://www.facebook.com/starlinknomad", "_blank");
  };
  const handleInstaClick = () => {
    window.open("https://www.instagram.com/starlinknomad/", "_blank");
  };

  const handleDeviceCountChange = (e) => {
    const count = Number(e.target.value);
    setDeviceCount(count);

    if (dateDuration > 0) {
      calculateMainPrice(dateDuration, count);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    setSelectedDates(dates);

    if (dates) {
      const [start, end] = dates;
      const duration = end.diff(start, "days");
      setDateDuration(duration);
      localStorage.setItem("duration", duration);
      if (duration > 0) {
        calculateMainPrice(duration, deviceCount);
      }
    } else {
      setDateDuration(0);
      setMainPrice(0);
    }
  };

  const setTabs = (item) => {
    setSelectedTab(item);
  };

  const calculateMainPrice = (duration, count) => {
    let price = 0;
    if (duration >= 1 && duration <= 9) {
      price = 100000 * count * duration;
    } else if (duration >= 10 && duration <= 19) {
      price = 90000 * count * duration;
    } else if (duration >= 20 && duration <= 29) {
      price = 80000 * count * duration;
    } else if (duration >= 30 && duration <= 39) {
      price = 80000 * count * duration;
    } else {
      price = 100000 * count * duration;
    }
    setMainPrice(price);
  };

  const handleSelect = (item) => {
    if (dateDuration === 0) {
      notification.error({
        message: "Талбар дутуу",
        description: "Түрээслэх огноог эхлээд сонгоно уу",
        duration: 3,
      });
      return;
    }
    const isSelected = selectedAdditionalItems.some(
      (selectedItem) => selectedItem.id === item.id
    );

    let updatedSelectedItems;
    if (isSelected) {
      updatedSelectedItems = selectedAdditionalItems.filter(
        (selectedItem) => selectedItem.id !== item.id
      );
    } else {
      updatedSelectedItems = [
        ...selectedAdditionalItems,
        { ...item, duration: dateDuration },
      ];
    }
    setSelectedAdditionalItems(updatedSelectedItems);

    const newTotalPrice = updatedSelectedItems.reduce(
      (sum, selectedItem) => sum + selectedItem.price * dateDuration,
      0
    );
    setAddPrice(newTotalPrice);
  };

  useEffect(() => {
    setTotalPrice(mainPrice + addPrice);
  }, [mainPrice, addPrice]);

  const addShoppingCard = () => {
    if (dateDuration === 0) {
      notification.error({
        message: "Талбар дутуу",
        description: "Түрээслэх огноог эхлээд сонгоно уу",
        duration: 3,
      });
      return;
    }
    const cartItems = [
      ...selectedAdditionalItems,
      { ...sItems[itemId - 1], duration: dateDuration },
    ];

    // console.log("card: ", cartItems);
    localStorage.setItem("cartRentItems", JSON.stringify(cartItems));
    navigate(`/spr`);
  };

  const tabItems = [
    {
      label: (
        <div
          className={`bs-label ${selectedTab !== 0 ? "" : "selected"}`}
          onClick={() => setTabs(0)}
        >
          <div className="bs-label-img-cont">
            {selectedTab === 0 ? (
              <img className="starlink-icon" src={imageIcon} alt="dd"></img>
            ) : (
              <img
                className="starlink-icon"
                src="https://icons.iconarchive.com/icons/iconsmind/outline/256/Photos-icon.png"
                alt="ll"
              ></img>
            )}
          </div>
          <span>Photos</span>
        </div>
      ),
      key: "1",
      children: (
        <div style={{ padding: 20 }}>
          <div className="bs-tab-content">
            <div className="starlink-icon">
              <Gallery item={id}></Gallery>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: (
        <div
          className={`bs-label ${selectedTab === 1 ? "selected" : ""}`}
          onClick={() => setTabs(1)}
        >
          <div className="bs-label-img-cont">
            {selectedTab === 1 ? (
              <img className="starlink-icon" src={imageIcon} alt="dd"></img>
            ) : (
              <img
                className="starlink-icon"
                src="https://www.iconpacks.net/icons/1/free-video-icon-818-thumb.png"
                alt="dd"
              ></img>
            )}
          </div>
          <span>Intro</span>
        </div>
      ),
      key: "2",
      children: (
        <div style={{ padding: 20 }}>
          <div className="bs-tab-content ">
            {id === "2" ? (
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ZpIKs1N0WFA?si=gfcB8oQ5DGL44yba"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            ) : (
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/PMAPKr4PW8A?si=Y5OxQulVyMVJ1uXw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
        }}
        className="flex flex-col min-h-screen"
      >
        <Header></Header>
        <ToolbarSecond></ToolbarSecond>
        <div
          style={{
            maxWidth: 1400,
            margin: "0px auto",
          }}
        >
          <div className="href-text " style={{ marginLeft: -30 }}>
            <span onClick={() => navigate(`/home`)} className="l-menu">
              Нүүр{" "}
            </span>
            <span onClick={() => navigate(`/rent-list`)} className="l-menu">
              / Түрээслэх
            </span>
            <span> / {itemId === 1 ? sItems[0].name : sItems[1].name}</span>
          </div>
        </div>
        <div className="bs-container ">
          <div className="bf-container-tabs">
            <Tabs
              className="tab-height"
              tabPosition={tabPosition}
              items={tabItems}
              centered
            />
          </div>

          <div className="bs-second-container">
            <div className="bs-second-container-header">
              {itemId === 1 ? sItems[0].name : sItems[1].name}
            </div>
            <div className="  margin-bottom-20 display-row">
              <div
                style={{
                  padding: 10,
                  borderRadius: 10,
                  marginTop: 20,
                }}
              >
                <div>
                  <span style={{ fontSize: 14 }}>Түрээслэх огноо:</span>
                  <RangePicker
                    style={{
                      marginLeft: 30,
                      border: "3px solid black",
                    }}
                    placeholder={["эхлэх", "дуусах"]}
                    // defaultValue={defaultRange}
                    onChange={handleDateChange}
                  />
                  <span style={{ marginLeft: 20 }}>
                    {dateDuration ? `${dateDuration} хоног` : ""}
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <span style={{ fontSize: 14, marginTop: 20 }}>
                      Төхөөрөмжийн тоо:
                    </span>
                    <div
                      style={{
                        marginLeft: 12,
                        marginTop: 17,
                      }}
                    >
                      <Input
                        type="number"
                        step={step}
                        min={min}
                        max={max}
                        value={deviceCount}
                        style={{
                          border: "1px solid lightgrey",
                          height: 30,
                          width: 60,
                          borderRadius: 3,
                          paddingLeft: 20,
                          fontSize: 14,
                        }}
                        onChange={handleDeviceCountChange}
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bs-header-text" style={{ width: 420 }}>
              <div className="color-3b3e40 display-column">
                <span
                  style={{ fontSize: 18, fontWeight: "bolder", marginLeft: 5 }}
                >
                  Үнийн мэдээлэл (хоногоор)
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 20,
                  }}
                >
                  <div
                    className="font-size-14 margin-top-5"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div>
                      1-9 хоног:
                      {itemId === 1
                        ? sItems[0].rentPrice[0].toLocaleString()
                        : sItems[1].rentPrice[0].toLocaleString()}
                    </div>
                    <div>
                      10-19 хоног:
                      {itemId === 1
                        ? sItems[0].rentPrice[1].toLocaleString()
                        : sItems[1].rentPrice[1].toLocaleString()}
                    </div>
                  </div>
                  <div
                    className="font-size-14 margin-top-5"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 30,
                    }}
                  >
                    <div>
                      20-29 хоног:{" "}
                      {itemId === 1
                        ? sItems[0].rentPrice[2].toLocaleString()
                        : sItems[1].rentPrice[2].toLocaleString()}
                    </div>
                    <div>
                      30-аас дээш хоног:{" "}
                      {itemId === 1
                        ? sItems[0].rentPrice[3].toLocaleString()
                        : sItems[1].rentPrice[3].toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h4 className="margin-top-40 margin-bottom-20">
                ХАМТ ТҮРЭЭСЛЭХ БОЛОМЖТОЙ
              </h4>
              <div className="bs-check-grid">
                {additionals[1].subItems.map((item, index) => (
                  <div
                    className="bf-select-item"
                    key={index}
                    onClick={() => handleSelect(item)}
                  >
                    <div
                      className={`bf-col-1 ${
                        selectedAdditionalItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )
                          ? "clicked"
                          : ""
                      }`}
                    >
                      {/* <Checkbox
                        className="bs-checkbox"
                        checked={selectedAdditionalItems.includes(item.id)}
                        onChange={() => handleAdditionalSelect(item.id)}
                      ></Checkbox> */}
                      <div className="rent-check-cont">
                        <img
                          alt={item.name}
                          className="starlink-icon"
                          src={item.img}
                        />
                      </div>
                      <div
                        className="rent-add-item"
                        onClick={handleOpenModal}
                        style={{
                          marginLeft: 20,
                          textDecoration: "none", // Remove underline
                          color: "inherit", // Inherit text color
                        }}
                      >
                        {/* <a
                          href={`/rent/${item.id}/detail`} // Set the URL you want to navigate to
                          style={{
                            width: 800,
                            marginLeft: 20,
                            textDecoration: "none", // Remove underline
                            color: "inherit", // Inherit text color
                          }}
                        > */}
                        {item.name}
                        {/* </a> */}
                        <Modal
                          isOpen={isModalOpen}
                          onClose={handleCloseModal}
                          content={<div>{modalContent}</div>}
                        />
                        <span
                          style={{
                            width: 400,
                            marginLeft: 20,
                          }}
                        >
                          {item.priceTag}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mr-30">
          <hr className="bs-hr" />
        </div>
        <div className="bs-s-cont">
          <div className="display-column margin-left-40">
            <h3 className="bs-s-header ">{sItems[id - 1].descMain}</h3>
            <div className="bs-s-desc ">
              {itemId === 1 ? (
                <>
                  {sItems[0].descriptionObject.map((item, index) => (
                    <div key={index} className="margin-top-5 font-size-18">
                      <div>
                        <span className="bs-s-dot"></span>
                        <span>{item.value}</span>
                      </div>
                      {item.subValue && (
                        <div className="sub-values">
                          {item.subValue.map((i, k) => (
                            <div key={k}>
                              <span style={{ marginLeft: 40 }}>{i}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {sItems[1].descriptionObject.map((item, index) => (
                    <div key={index} className="margin-top-5 font-size-18">
                      <div>
                        <span className="bs-s-dot"></span>
                        <span>{item.value}</span>
                      </div>
                      {item.subValue && (
                        <div className="sub-values">
                          {item.subValue.map((i, k) => (
                            <div key={k}>
                              <span style={{ marginLeft: 40 }}>{i}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="add-c-1">
            {/* <div className="bs-s-sub"> */}
            <div style={{ padding: 20 }}>
              <h3 className="bs-s-sub-header " style={{ marginTop: -30 }}>
                Дагалдах зүйлс
              </h3>

              {itemId === 1 ? (
                <>
                  {sItems[0].accessories.length > 0 && (
                    <div
                      style={{
                        padding: 20,
                        border: "1px solid grey",
                        height: "80%",
                        width: 320,
                      }}
                    >
                      <>
                        {sItems[id - 1].accessories.map((item, index) => (
                          <div style={{ listStyleType: "circle" }}>
                            <span className="bs-s-dot"></span>
                            <span style={{ fontSize: 14 }}>{item}</span>
                          </div>
                        ))}
                      </>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {sItems[1].accessories.length > 0 && (
                    <div
                      style={{
                        padding: 20,
                        border: "1px solid grey",
                        height: "80%",
                        width: 320,
                      }}
                    >
                      <>
                        {sItems[id - 1].accessories.map((item, index) => (
                          <div style={{ listStyleType: "circle" }}>
                            <span className="bs-s-dot"></span>
                            <span style={{ fontSize: 14 }}>{item}</span>
                          </div>
                        ))}
                      </>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div className="bf-row-1">
            <div className="bs-row-1-cont">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ZpIKs1N0WFA?si=gfcB8oQ5DGL44yba"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div className="bs-video-cont">
            <div className="bs-video-cont-img-c">
              <img
                src={u2}
                className="bs-video-cont-img-1"
                alt="Tab 2 Content"
              />
            </div>
            <div className="bs-video-cont-img-d">
              <img src={u1} className="bs-video-cont-img" alt="Tab 2 Content" />
            </div>
          </div>
        </div>
        <div style={{ height: 80 }}></div>
      </div>
      <div
        style={{
          backgroundColor: "#f7f9fa",
        }}
        className="buy-button-container "
      >
        <div className="fl-container" style={{ marginBottom: 40 }}>
          <div className="fl-sub">
            <div className="fl-sub-1 ">
              {/* <div className="fl-img">
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={b_long}
                ></img>
              </div> */}

              <div
                className="fl-txt "
                style={{ marginLeft: -10 }}
                onClick={() => navigate(`/home?id=${"starlink3"}`)}
              >
                Түгээмэл асуултууд
              </div>
              <div className="fl-txt " onClick={() => navigate("/contact")}>
                Холбоо барих
              </div>
              <div className="fl-txt" onClick={callInstruction}>
                Заавар татах
              </div>
              {isVisible && (
                <div
                  className="fl-txt"
                  onClick={() =>
                    window.open("./assets/pdf/MobileBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Хөдөлгөөнт идэвхжүүлэх
                </div>
              )}
              {isVisible && (
                <div
                  className="fl-txt"
                  onClick={() =>
                    window.open("./assets/pdf/SuurinBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Суурин идэвхжүүлэх
                </div>
              )}
              <div
                className="fl-sub-2"
                style={{ marginLeft: 40, marginTop: 5 }}
              >
                <img
                  className="fl-img-2"
                  src={fb_black}
                  onClick={() => handleFbClick()}
                ></img>
                <img
                  className="fl-img-3"
                  onClick={() => handleInstaClick()}
                  src={insta_black}
                ></img>
              </div>
            </div>

            <div className="bs-price-cont " style={{ marginRight: 30 }}>
              <span
                className="bs-pric-txt"
                style={{
                  fontSize: 18,
                  color: totalPrice > 0 ? "red" : "black",
                }}
              >
                MNT {totalPrice && totalPrice.toLocaleString()}
              </span>
              <button className="bs-button" onClick={addShoppingCard}>
                ТҮРЭЭСЛЭХ
              </button>
            </div>
          </div>
          <div className="fl-hr">
            <hr className="fl-hr-s" />
          </div>
          <div className="fl-cont-2">
            <div className="fl-add">
              Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503
              тоот(Циркийн урд)
            </div>
            <div className="fl-phone" style={{ marginRight: 5 }}>
              {/* +976 75990033 */}
            </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          backgroundColor: "#f7f9fa",
        }}
        className="buy-button-container "
      >
        <div className="bs-price-cont ">
          <span className="bs-pric-txt">
            MNT {totalPrice && totalPrice.toLocaleString()}
          </span>
          <button className="bs-button" onClick={addShoppingCard}>
            ТҮРЭЭСЛЭХ
          </button>
        </div>
      </div> */}
      {/* <Footer2 type="other"></Footer2> */}
    </div>
  );
};

export default RentProduct;
