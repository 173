import React from "react";
import ic_phone from "../../assets/icons/ic_phone.png";
import ic_call from "../../assets/icons/ic_call.png";
import taiwan from "../../assets/gallery/Taiwan.png";
import royal from "../../assets/gallery/Royal.png";
import saruul from "../../assets/gallery/Saruul.png";
import FooterL from "../../Footer";
import Header from "../../Header";
import InstagramEmbed from "./InstaEmbed";
import FacebookEmbed from "./FbEmbed";
import ToolbarSecond from "../../ToolbarSecond";

const Contact = () => {
  // const callGoogleApi = () => {
  //   window.open("https://maps.app.goo.gl/JdViJ3NKc9T17aH39", "_blank");
  // };
  const items = [
    {
      name: "Дэлгүүр",
      address:
        "Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503 тоот (Циркийн урд)    ",
      f: "88054610",
      phone: "75990033, 99996384",
      email: "info@starlinknomad.mn",
      img: royal,
      i_link: "https://maps.app.goo.gl/9PMvWH7vfeUZzUeWA",
    },
    {
      name: "Засварын төв",
      address:
        "Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Саруул захын зүүн хойно, Саруул оффис, 402 тоот",
      phone: "75990033, 99997428",
      email: "service@starlinknomad.mn",
      img: saruul,
      i_link: "https://maps.app.goo.gl/8b2BG7qaaxxe7a3R6",
    },
    {
      name: "Шийдлийн төв",
      address:
        "Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Монгол-Тайвань төв, 511 тоот(Урт цагааны урд)",
      phone: "75990044, 99996030",
      email: "solution@starlinknomad.mn",
      img: taiwan,
      i_link: "https://maps.app.goo.gl/SLtCmzcGiSjAjdQE8",
    },
  ];

  const handleUrl = (mapUrl) => {
    window.open(mapUrl, "_blank");
  };

  return (
    <div>
      <Header></Header>
      <ToolbarSecond></ToolbarSecond>
      <div
        style={{
          backgroundColor: "white",
          width: 1400,
          margin: "0px auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            marginTop: 100,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ height: 80, width: 80 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                src={ic_call}
              ></img>
            </div>
            <h3 style={{ marginLeft: 20 }}> {75990033}</h3>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: 20,
              listStyle: "none",
              marginBottom: 20,
              marginTop: 60,
            }}
          >
            {items.map((item) => (
              <>
                <div style={{ padding: 16 }}>
                  <h3 style={{ width: "100%" }}>{item.name}</h3>
                  <div style={{ height: 160 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 20,
                        justifyContent: "flex-start",
                      }}
                    >
                      <div style={{ width: 40, height: 20 }}>
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={ic_phone}
                          // onClick={callGoogleApi()}
                        ></img>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ marginLeft: 10, height: 70 }}>
                          {item.address}
                        </span>
                        {item.name === "Дэлгүүр" && (
                          <div style={{ marginLeft: 10, marginTop: -20 }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Санхүү:
                            </span>
                            <span style={{ marginLeft: 10 }}>{item.f}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 20,
                        justifyContent: "flex-start",
                        // marginLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          alignItems: "self-start",
                        }}
                      >
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={ic_phone}
                        ></img>
                      </div>
                      <div style={{ marginLeft: 20, fontSize: 16 }}>
                        {item.name === "Дэлгүүр" && (
                          <span style={{ fontWeight: "bold" }}>
                            Борлуулагч:
                          </span>
                        )}
                        {/* <span style={{ fontWeight: "bold" }}>Борлуулагч:</span> */}
                        <span style={{ marginLeft: 10 }}>{item.phone}</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 20,
                        // marginLeft: 20,
                      }}
                    >
                      <div style={{ width: 10, height: 10 }}>
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src="https://e7.pngegg.com/pngimages/914/782/png-clipart-white-and-gray-mail-application-email-computer-icons-grey-mobile-phones-grey-background-miscellaneous-angle.png"
                        ></img>
                      </div>
                      <div style={{ marginLeft: 20, fontSize: 16 }}>
                        {/* <span style={{ fontWeight: "bold" }}>Имэйл:</span> */}
                        <span style={{ marginLeft: 10 }}>{item.email}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: 70 }}
                    onClick={() => handleUrl(item.i_link)}
                  >
                    <img
                      style={{
                        // width: 425,
                        // height: 400,
                        border: "1px solid #c8c6c7",
                      }}
                      src={item.img}
                    ></img>
                  </div>
                </div>
              </>
            ))}
          </div>
          <hr style={{ marginTop: 20 }}></hr>
          <div style={{ marginTop: 40, display: "flex", flexDirection: "row" }}>
            <div style={{ height: 520, width: 460 }}>
              <FacebookEmbed></FacebookEmbed>
            </div>
            <div style={{ height: 520, width: 420, marginLeft: 30 }}>
              <InstagramEmbed></InstagramEmbed>
            </div>
            <div style={{ height: 520, width: 440, marginLeft: 50 }}>
              <iframe
                width="440"
                height="470"
                src="https://www.youtube.com/embed/yEpNJpiTgHo?si=Z_2f5vebjiop7G01"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            {/* <div style={{ height: 520, width: 400 }}>
              <TwitterEmbed></TwitterEmbed>
            </div> */}
          </div>
        </div>
      </div>
      <FooterL></FooterL>
    </div>
  );
};

export default Contact;
