import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UpOutlined,
  DownOutlined,
  FileOutlined,
  HomeOutlined,
  ProductFilled,
  ProjectOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

import { Tabs, Tooltip } from "antd";

const Toolbar = () => {
  const navigate = useNavigate();

  const idList = ["landing", "starlink", "starlink2", "intro", "starlink3"];
  const [isVisible, setIsVisible] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const toggleVisibility = () => {
    const scrollContainer = document.querySelector(".background-container");
    if (scrollContainer.scrollTop > 850) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    setCurrentSectionIndex(getCurrentSectionIndex());
  };

  const scrollToTop = () => {
    const scrollContainer = document.querySelector(".background-container");
    scrollContainer.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToIntro = () => {
    const introSection = document.getElementById("starlink");
    if (introSection) {
      introSection.scrollIntoView({ behavior: "smooth" });
      setCurrentSectionIndex(idList.indexOf("starlink"));
    }
  };

  const handleSupportClick = () => {
    navigate(`/home?id=${"starlink3"}`);
  };

  const getCurrentSectionIndex = () => {
    for (let i = 0; i < idList.length; i++) {
      const section = document.getElementById(idList[i]);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight) {
          return i;
        }
      }
    }
    return -1;
  };

  const scrollToNextSection = () => {
    if (currentSectionIndex >= 0 && currentSectionIndex < idList.length - 1) {
      const nextSectionId = idList[currentSectionIndex + 1];
      const nextSection = document.getElementById(nextSectionId);
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: "smooth" });
      }

      if (currentSectionIndex === 4) {
      }
    }
  };

  const scrollToPreviousSection = () => {
    if (currentSectionIndex > 0) {
      const previousSectionId = idList[currentSectionIndex - 1];
      const previousSection = document.getElementById(previousSectionId);
      if (previousSection) {
        previousSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    const scrollContainer = document.querySelector(".background-container");
    scrollContainer.addEventListener("scroll", toggleVisibility);
    return () => {
      scrollContainer.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed-buttons-container centered">
      <Tooltip title="ӨМНӨХ" placement="right">
        <button
          onClick={scrollToPreviousSection}
          className={`toolbar-button ${isVisible ? "visible" : ""}`}
        >
          <UpOutlined />
        </button>
      </Tooltip>
      <Tooltip title="НҮҮР" placement="right">
        <button
          onClick={scrollToTop}
          className={`toolbar-button ${isVisible ? "visible" : ""}`}
        >
          <HomeOutlined />
        </button>
      </Tooltip>

      <Tooltip title="ХУДАЛДАЖ АВАХ" placement="right">
        <button
          onClick={() => navigate(`/buy-list`)}
          className={`toolbar-button ${isVisible ? "visible" : ""}`}
        >
          <ProductFilled />
        </button>
      </Tooltip>
      <Tooltip title="ТҮРЭЭСЛЭХ" placement="right">
        <button
          onClick={() => navigate(`/rent-list`)}
          className={`toolbar-button ${isVisible ? "visible" : ""}`}
        >
          <ProjectOutlined />
        </button>
      </Tooltip>
      <Tooltip title="ТАНИЛЦУУЛГА" placement="right">
        <button
          onClick={() => scrollToIntro()}
          className={`toolbar-button ${isVisible ? "visible" : ""}`}
        >
          <FileOutlined />
        </button>
      </Tooltip>

      <Tooltip title="АСУУЛТ, ХАРИУЛТ" placement="right">
        <button onClick={handleSupportClick} className={`toolbar-button `}>
          <QuestionOutlined />
        </button>
      </Tooltip>
      {currentSectionIndex < idList.length - 1 && currentSectionIndex !== 4 && (
        <Tooltip title="ДАРААХ" placement="right">
          <button
            onClick={scrollToNextSection}
            className={`toolbar-button ${isVisible ? "visible" : ""}`}
          >
            <DownOutlined />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default Toolbar;
