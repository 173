// import  { useState } from "react";
import fb_black from "./assets/icons/7_black.png";
import b_long from "./assets/black_logo_long.png";
import insta_black from "./assets/icons/8_black.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const FooterL = ({ type }) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleFbClick = () => {
    window.open("https://www.facebook.com/starlinknomad", "_blank");
  };
  const handleInstaClick = () => {
    window.open("https://www.instagram.com/starlinknomad/", "_blank");
  };

  const callInstruction = () => {
    if (isVisible === true) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="fl-container" style={{marginBottom:30}}>
      <div className="fl-sub ">
        <div className="fl-sub-1 ">
          <div className="fl-img">
            <img style={{ height: "100%", width: "100%" }} src={b_long}></img>
          </div>

          <div
            className="fl-txt "
            onClick={() => navigate(`/home?id=${"starlink3"}`)}
          >
            Түгээмэл асуултууд
          </div>
          <div className="fl-txt " onClick={() => navigate("/contact")}>
            Холбоо барих
          </div>
          <div className="fl-txt" onClick={callInstruction}>
            Заавар татах
          </div>
          {isVisible && (
            <div
              className="fl-txt"
              onClick={() =>
                window.open("./assets/pdf/MobileBurtgel-StarlinkNomad.pdf")
              }
            >
              Хөдөлгөөнт идэвхжүүлэх
            </div>
          )}
          {isVisible && (
            <div
              className="fl-txt"
              onClick={() =>
                window.open("./assets/pdf/SuurinBurtgel-StarlinkNomad.pdf")
              }
            >
              Суурин идэвхжүүлэх
            </div>
          )}
        </div>
        <div className="fl-sub-2">
          <img
            className="fl-img-2"
            src={fb_black}
            onClick={() => handleFbClick()}
          ></img>
          <img
            className="fl-img-3"
            onClick={() => handleInstaClick()}
            src={insta_black}
          ></img>
        </div>
      </div>
      <div className="fl-hr">
        <hr className="fl-hr-s" />
      </div>
      <div className="fl-cont-2">
        <div className="fl-add">
          Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503
          тоот(Циркийн урд)
        </div>
        <div className="fl-phone">+976 75990033</div>
      </div>
    </div>
  );
};

export default FooterL;
